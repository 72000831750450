// @ts-nocheck
import '../styles.css';
import { useCallback, useState, MutableRefObject, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import { isNil, pick, get, size, isEmpty } from 'lodash';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography, useMediaQuery, Tooltip, Grid, DialogContentText } from '@mui/material';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { TypeComputedProps, TypeSortInfo } from '@inovua/reactdatagrid-enterprise/types';
import DateFilter from '@inovua/reactdatagrid-enterprise/DateFilter';
import { MangoQuerySortDirection } from 'rxdb/dist/types/types/rx-query';
import {exportCSV, exportExcel, getRegexByOperator} from '../../../utils';
import LicensedReactDataGrid from '../../../components/UI/LicensedReactDataGrid';
import { getDatabase, TDIDb } from '../../../rxdb';
import { TblDdListDefaultsDocument } from '../../../api/queries/tblDDListDefaults/rxdb';
import DeleteRecordBtn from '../../../components/DeleteRecordBtn';
import { useAppState } from 'src/contexts/app-state';
import { useAuth } from 'src/contexts/auth';
import GridRowAttachmentIcon from "../../../components/UI/LicensedReactDataGrid/components/GridRowAttachmentIcon";
import GridDateFilterDialog from 'src/components/UI/GridDateFilterDialog';
import moment from 'moment';
import CompleteTaskButton from 'src/components/CompleteTaskButton';
import { WorkIssuesDocument } from 'src/rxdb/collections/WorkIssues/rxdb';
import { taskDeleteWarningMessage } from 'src/consts';

type WorkIssuesGridProps = {
  darken: boolean;
  onSelect: (issue: WorkIssuesDocument) => void;
  sourceRoot?: boolean;
  moduleLevelSearch?: any;
  current?: boolean;
  inspection?: boolean;
  completeTaskShow?:boolean;
  onCancel?: () => void;
};

const filter = [
  {
    name: 'Subject',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'AssignedTo',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'DateDue',
    operator: 'inrange',
    type: 'date',
    value: '',
  },
  {
    name: 'DateStart',
    operator: 'inrange',
    type: 'date',
    value: '',
  },
  {
    name: 'Department',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'Equipment',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'fldWorkList',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'fldStatus',
    operator: 'contains',
    type: 'string',
    value: '',
  },
];

let currentCount = 0;

const transformData = async (issue: WorkIssuesDocument) => ({
  ...pick(issue, [
    'JobNumber',
    'Subject',
    'DateDue',
    'DateStart',
    'Department',
    'fldWorkList',
    'fldSRHKey',
    'AssignedTo',
    'fldStatus',
    'fldSchedMaintKey',
  ]),
  Equipment: (await issue.populate('EqKey'))?.UniqueName,
  //Note: commented category and location as remove rowdetail so no need for now
  // category: (await getFullPath(issue)),
  // location: (await getFullLocationPath(issue)),
  // Department: (await issue.populate('Department'))?.fldMember,
  // fldWorkList: (await issue.populate('fldWorkList'))?.fldMember,
  original: issue,
});

const getSelectorByFilterName = async (
  name: string,
  value: string,
  operator: string,
  db: TDIDb
) => {
  switch (name) {
    case 'JobNumber': {
      return {
        JobNumber: { $in: value },
      };
    }
    case 'Subject':
      return {
        Subject: { $regex: getRegexByOperator(operator, value) },
      };
    case 'AssignedTo':
      return {
        AssignedTo: { $regex: getRegexByOperator(operator, value) },
      };
    case 'fldWorkList': {
      // const workLists = await db.tblddlistdefaults
      //   .find({
      //     selector: {
      //       fldMember: { $regex: getRegexByOperator('startsWith', value) },
      //       fldListName: { $eq: 'Work List Names' },
      //     },
      //   })
      //   .exec();
      // return {
      //   fldWorkList: { $in: workLists.map((e) => e.primary) },
      // };
      return {
        fldWorkList: { $regex: getRegexByOperator(operator, value) },
      };
    }
    case 'fldStatus':
      return {
        fldStatus: { $regex: getRegexByOperator(operator, value) },
      };

    case 'Department': {
      // const deps = await db.tblddlistdefaults
      //   .find({
      //     selector: {
      //       fldMember: { $regex: getRegexByOperator('startsWith', value) },
      //       fldListName: { $eq: 'Department' },
      //     },
      //   })
      //   .exec();
      // return {
      //   Department: { $in: deps.map((e) => e.primary) },
      // };
      return {
        Department: { $regex: getRegexByOperator(operator, value) },
      };
    }

    case 'Equipment': {
      const equipments = await db.equipment
        .find({
          selector: {
            UniqueName: { $regex: getRegexByOperator(operator, value) },
          },
        })
        .exec();
      return {
        EqKey: { $in: equipments.map((e) => e.primary) },
      };
    }

    case 'DateDue': {
      if (!isEmpty(value.start) && (isNil(value.end) || isEmpty(value.end))) {
        const dateformate = moment(value?.start)
          .utcOffset(0)
          .set({ hour: 0, minute: 0, second: 0 })
          .toISOString()
          .split('.')[0];
        return {
          DateDue: { $gte: dateformate },
        };
      }
      if (!isEmpty(value.end) && (isNil(value.start) || isEmpty(value.start))) {
        const dateformate = moment(value?.end)
          .utcOffset(0)
          .set({ hour: 0, minute: 0, second: 0 })
          .toISOString()
          .split('.')[0];
        return {
          DateDue: { $lte: dateformate },
        };
      }
      if (!isEmpty(value.start) && !isEmpty(value.end)) {
        const startdateformate = moment(value?.start)
          .utcOffset(0)
          .set({ hour: 0, minute: 0, second: 0 })
          .toISOString()
          .split('.')[0];
        const enddateformate = moment(value?.end)
          .utcOffset(0)
          .set({ hour: 0, minute: 0, second: 0 })
          .toISOString()
          .split('.')[0];
          return {
            DateDue: { 
              $gte: startdateformate,
              $lte: enddateformate
            },
        };
      }
      return {};
    }
    case 'DateStart': { // is not displayed on the grid anymore - is setup to [defaultVisible: false],
      if (!isEmpty(value.start) && (isNil(value.end) || isEmpty(value.end))) {
        const dateformate = moment(value?.start)
          .utcOffset(0)
          .set({ hour: 0, minute: 0, second: 0 })
          .toISOString()
          .split('.')[0];
        return {
          DateDue: { $gte: dateformate },
        };
      }
      if (!isEmpty(value.end) && (isNil(value.start) || isEmpty(value.start))) {
        const dateformate = moment(value?.end)
          .utcOffset(0)
          .set({ hour: 0, minute: 0, second: 0 })
          .toISOString()
          .split('.')[0];
        return {
          DateDue: { $lte: dateformate },
        };
      }
      if (!isEmpty(value.start) && !isEmpty(value.end)) {
        const startdateformate = moment(value?.start)
          .utcOffset(0)
          .set({ hour: 0, minute: 0, second: 0 })
          .toISOString()
          .split('.')[0];
        const enddateformate = moment(value?.end)
          .utcOffset(0)
          .set({ hour: 0, minute: 0, second: 0 })
          .toISOString()
          .split('.')[0];
        return {
            DateDue: { 
              $gte: startdateformate,
              $lte: enddateformate
            },
        };
      }
      return {};
    }

    default:
      return {};
  }
};

export const genOrOperator = (userData) => {
  const result = [];

  let comStr = '';
  if (userData.fldFirst) {
    comStr = comStr + userData.fldFirst;
  }

  if (userData.fldLast) {
    if (userData.fldFirst) {
      comStr = comStr + ' ' + userData.fldLast;
    } else {
      comStr = userData.fldLast;
    }
  }

  if (comStr !== '') {
    result.push({
      AssignedTo: {
        $regex: new RegExp(`.*${comStr}`, 'i'),
      },
    });
  }

  if (userData.fldPosition) {
    result.push({
      AssignedTo: { $regex: new RegExp(`.*${userData.fldPosition}`, 'i') },
    });
  }

  if (userData.Department) {
    result.push({
      AssignedTo: { $regex: new RegExp(`.*${userData.Department}`, 'i') },
    });
  }

  return result;
};

export const filterCurrentTask = async (issues: any) =>{
  const filterData = issues.filter((obj) => {
    const today = new Date();
    const dueDate = obj.DateDue ? new Date(obj.DateDue) : null;
    const startDate = obj.DateStart ? new Date(obj.DateStart) : null;
  
    return (
      (dueDate && dueDate <= today) ||
      (startDate && startDate <= today) ||
      (!obj.DateDue && !obj.DateStart)
    );
  });
  return filterData
}

export const filterInspectionTask = async (issues: any) =>{
  const filterData = issues.filter((obj) => obj.fldSMSType === "CHKL");
  return filterData;
}

const loadData = async (
  {
    skip,
    limit,
    filterValue,
    sortInfo,
  }: {
    sortInfo: TypeSortInfo;
    skip: number;
    limit: number;
    filterValue: any;
  },
  settingsData,
  userData,
  current,
  inspection,
  completeTaskShow,
): Promise<{ data: any[]; count: number }> => {
  if (!settingsData) {
    return { data: [], count: 0 };
  }

  const db = await getDatabase();

  let preSort = {
    Subject: 'asc' as MangoQuerySortDirection,
  };

  if (!isNil(sortInfo)) {
    if (sortInfo.name === 'Subject') {
      preSort = {
        [sortInfo.name]:
          sortInfo.dir === 1
            ? ('asc' as MangoQuerySortDirection)
            : ('desc' as MangoQuerySortDirection),
      };
    }
  }

  const sort = [
    {
      // accessDate: 'desc' as MangoQuerySortDirection,
      ...preSort,
    },
  ];

  // TODO: Handle filtervalue.OPERATOR

  let selector = {
    fldIsCheckList: {
      $eq: false,
    },
    Completed: {
      $eq: false,
    },
    deletedBy: {
      $eq: null,
    },
    $or:[
      {
        fldSMSType:{
          $regex: new RegExp('^(?!<CHKLIST).*$', 'i')
        }
      },
      {
        fldSMSType:{
          $eq: null
        }
      },
    ]
  };

  if(completeTaskShow){
    selector = {
      ...selector,
      $or:[
        {
          fldSMSType:{
            $regex: new RegExp('^(?!<CHKLIST|CHKL|CERT|CERTS).*$', 'i')
          }
        },
        {
          fldSMSType:{
            $eq: null,
          }
        }
      ]
    }
  }

  let orOperator = genOrOperator(userData);
  if (settingsData.fldAllDepts === 0) {
    if (settingsData.fldDeptHead) {
      selector = {
        ...selector,
        $or: [
          {
            Department: userData.Department,
          },
          {
            fldDeptCreator: userData.Department,
          },
        ],
      };
    } else {
      selector = {
        ...selector,
        $or: [
          ...orOperator,
          {
            $and: [
              {
                AssignedTo: {
                  $eq: null,
                },
              },
              {
                Department: {
                  $eq: userData.Department,
                },
              },
            ],
          },
        ],
      };
    }
  }

  await Promise.all(
    filterValue.map(async (v) => {
      if (isEmpty(v.value)) return v;

      const s = await getSelectorByFilterName(v.name, v.value, v.operator, db);

      selector = {
        ...selector,
        ...s,
      };
      return v;
    })
  );

  let issues = await db.workissues
    .find({
      selector,
      skip,
      limit,
      sort,
    })
    .exec();
  
  if (current) {
    issues = await filterCurrentTask(issues);
  }

  if (inspection) {
    issues = await filterInspectionTask(issues);
  }

  currentCount = size(issues)
  const data = await Promise.all(issues.map(transformData));
  return { data, count: length };
};

interface WorkIssuesGridRef {
  handleExport: (type: string) => void;
}

const WorkIssuesGrid = forwardRef<WorkIssuesGridRef, WorkIssuesGridProps>(
  ({ 
      onSelect, 
      inspection = false, 
      current = false, 
      sourceRoot = false, 
      moduleLevelSearch = null, 
      completeTaskShow= false,onCancel 
    }: WorkIssuesGridProps, ref) => {
    const [loading, setLoading] = useState<boolean>(false);
    const highlightedRowId = useRef<string>('-1');
    const [gridRef, setGridRef] = useState<any>(null);
    const [exportDialog, setExportDialog] = useState(null);
    const [count, setCount] = useState<number>(0);
    const [collapsedGroups, setCollapsedGroups] = useState({});
    const [groupCollapsedStatus, setGroupCollapsedStatus] = useState(false);
    const [rowSelected, setRowSelected] = useState(null);
    const { settingsPersonal } = useAppState();
    const { user } = useAuth();
    const isTablet = useMediaQuery('(min-width: 700px) and (max-width: 1200px)');
    const isMobile = useMediaQuery('(max-width: 420px)');

    useImperativeHandle(ref, () => ({
      handleExport: (type) => {
        handleDataExport(type);
      },
    }));

    // Find if any filters applied to grid
    const areFiltersActive = gridRef?.current.computedFilterValue.some(
      (f) => !isEmpty(f.value)
    );
    const currentFilters = get(gridRef, ['current', 'computedFilterValue'], []);

    const init = async (ref: MutableRefObject<TypeComputedProps | null>) => {
      const db = await getDatabase();
      // Always reload grid on new item / update / delete.
      db.workissues.$.subscribe(async (ev) => {
        if (ev.operation === 'INSERT' || ev.operation === 'UPDATE') {
          highlightedRowId.current = ev.documentId;
          ref.current?.reload();
        }
        if (ev.operation === 'DELETE') {
          highlightedRowId.current = '-1';
          ref.current?.reload();
        }
      });
    };

    useEffect(() => {
      if (isNil(settingsPersonal) || isNil(gridRef)) return;

      if(moduleLevelSearch && moduleLevelSearch.length) {
        gridRef?.current?.setFilterValue([
          ...gridRef.current.computedFilterValue.filter(
            (f) => f.name !== 'JobNumber'
          ),
          {
            name: 'JobNumber',
            value: moduleLevelSearch,
          },
        ]);
      }
      else {
        if (!isNil(gridRef)) {
          gridRef?.current?.setFilterValue(
            gridRef.current.computedFilterValue.filter(
              (f) => f.name !== 'JobNumber'
            )
          );
        }
      }

      // TODO: Please fix that, it creates subscription that's never unsubscribed
      init(gridRef);
    }, [settingsPersonal, gridRef, moduleLevelSearch]);
    const onReady = (ref: MutableRefObject<TypeComputedProps | null>) => {
      setGridRef(ref);
      // init(ref);
    };
    const onLoadingChange = (status: boolean) => {
      // If loading completed - check if there any tasks that needs to be highlighted.
      if (!status && highlightedRowId.current !== '-1') {
        if(!completeTaskShow){
          gridRef?.current?.scrollToId(highlightedRowId.current|| '-1');//Added -1 because if user complete task then it gives null error as this task removed from grid
        }
        gridRef?.current?.setSelectedById(highlightedRowId.current, true);
        highlightedRowId.current = '-1';
      }
      setLoading(status);
    };

    const dataSource = useCallback(
      (gridData: any) => loadData(gridData, settingsPersonal, user, current, inspection, completeTaskShow),
      [settingsPersonal, user]
    );

    const onRowClick = useCallback(
      ({ data }) => {
        if (Object.keys(rowSelected || {}).length < 2) {
          onSelect(data.original);
        }
      },
      [rowSelected]
    );

    const exportData = (type = 'CSV' | 'xlsx', withFilters = true) => {
      setExportDialog(null);

      switch (type) {
        case 'CSV':
          return onExportToCSV(withFilters);
        case 'xlsx':
          return onExportToExcel(withFilters);
        default:
      }
    };

    const onExportToExcel = async (withFilters: boolean) => {
      const { data } = await loadData(
        {
          filterValue: withFilters ? currentFilters : [],
        },
        settingsPersonal,
        user,
        current,
        inspection,
        completeTaskShow
      );

      const columnsData = gridRef.current.visibleColumns.map((c: any) => ({
        header: c.header,
        key: c.id,
      }));
      const columns = columnsData.filter(item => {
        return item.header && typeof item.header === 'string';
      });
      const rows = data.map((data: any) =>
        pick(data, ['Subject', 'Equipment', 'Department', 'AssignedTo', 'fldWorkList', 'DateDue'])
      );
      return exportExcel(columns, rows);
    };

    const onExportToCSV = async (withFilters: boolean) => {
      const columns = gridRef.current.visibleColumns;

      const { data } = await loadData(
        {
          filterValue: withFilters ? currentFilters : [],
        },
        settingsPersonal,
        user,
        current,
        inspection,
        completeTaskShow
      );

      const rows = data.map((data: any) =>
        pick(data, ['Subject', 'Equipment', 'Department', 'fldWorkList', 'DateDue'])
      );
      return exportCSV(columns, rows);
    };

    const handleDataExport = (type = 'CSV' | 'xlsx') => {
      if (areFiltersActive) {
        setExportDialog({
          visible: true,
          type,
          title: type === 'CSV' ? 'CSV' : 'Excel',
        });

        return;
      }

      exportData(type, false);
    };

    const renderGroupTitle = (value, { data }) => {
      const columns = data.fieldPath.map((col) =>
        col === data.name ? col.toUpperCase() : col
      );
      const path = columns && columns.length && columns.join('>');
      let dataValue = data.value;
      if (path.includes('DateDue') || path.includes('DateStart') ) {
        dataValue = moment(data.value).format('DD-MMM-YYYY');
      }
      return isNil(data.value) ? `No ${path} Assigned` : dataValue;
    };

    const taskCount = async () => {
      const db = await getDatabase();
      let selector = {
        fldIsCheckList: {
          $eq: false,
        },
        Completed: {
          $eq: false,
        },
        deletedBy: {
          $eq: null,
        },
        $or:[
          {
            fldSMSType:{
              $regex: new RegExp('^(?!<CHKLIST).*$', 'i')
            }
          },
          {
            fldSMSType:{
              $eq: null,
            }
          }
        ]
      };

      if(completeTaskShow){
        selector = {
          ...selector,
          $or:[
            {
              fldSMSType:{
                $regex: new RegExp('^(?!<CHKLIST|CHKL|CERT|CERTS).*$', 'i')
              }
            },
            {
              fldSMSType:{
                $eq: null,
              }
            }
          ]
        }
      }
      let orOperator = genOrOperator(user);
      if (settingsPersonal.fldAllDepts === 0) {
        if (settingsPersonal.fldDeptHead) {
          selector = {
            ...selector,
            $or: [
              {
                Department: user.Department,
              },
              {
                fldDeptCreator: user.Department,
              },
            ],
          };
        } else {
          selector = {
            ...selector,
            $or: [
              ...orOperator,
              {
                $and: [
                  {
                    AssignedTo: {
                      $eq: null,
                    },
                  },
                  {
                    Department: {
                      $eq: user.Department,
                    },
                  },
                ],
              },
            ],
          };
        }
      }
      let issues = await db.workissues
        .find({
          selector,
        })
        .exec();
        if(current){
          issues = await filterCurrentTask(issues)
        }
      setCount(size(issues));
    };

    useEffect(() => {
      if (settingsPersonal && user) {
        taskCount();
      }
    }, [loading, settingsPersonal, user]);

    const footerRows = [
      {
        position: 'end',
        render: {
          icons: () => {
  
            const style = {
              paddingLeft: 20,
            };
  
            return (
              <div style={style}>
                Total Records: {currentCount}/{count}
              </div>
            );
          },
        },
        colspan: {
          icons: (_, computedProps) => computedProps.visibleColumns.length,
        },
      },
    ];

    const CollapseButton = () => {
      if (groupCollapsedStatus) {
        setGroupCollapsedStatus(false);
        return gridRef.current.collapseAllGroups();
      }
      if (!groupCollapsedStatus) {
        setGroupCollapsedStatus(true);
        return gridRef.current.expandAllGroups();
      }
    };
   
    const columns = [
      {
        id: 'icons',
        header: 'Icons',
        defaultWidth: 80,
        visible: !isMobile,
        render: ({ data }: any) =>
          <GridRowAttachmentIcon
            selector={{
              $or: [
                { fldRecordKey: { $eq: data.JobNumber } },
                { fldRecordKey: { $eq: data.fldSchedMaintKey } }
              ]
            }}
          />,
         onRender: (
          cellProps: any,
          { data }: { data: WorkIssuesDocument }
        ) => {
          if (
            !isEmpty(data?.DateDue) &&
            new Date().toISOString() > data?.DateDue
          ) {
            cellProps.style.borderLeft = 'red 3px solid';
          } else {
            cellProps.style.borderLeft = '#e4e3e2 3px solid';
          }
        },
      },
      {
        name: 'Subject',
        header: 'Task Subject',
        defaultFlex: 2,
      },
      {
        name: 'DateDue',
        header: 'Due Date',
        defaultFlex: 1.5,
        visible: !isMobile,
        headerAlign: 'start' as any,
        textAlign: 'end' as any,
        dateFormat: 'DD-MMM-YYYY',
        filterEditor: DateFilter,
        filterEditorProps: (props, { index }) => {
          return { placeholder: index == 1 ? 'End...' : 'Start...' };
        },
        render: ({ value, cellProps: { dateFormat } }) => {
          if (!isNil(value)) {
            return moment(value).format(dateFormat);
          }
        },
      },
      {
        name: 'AssignedTo',
        header: 'Assigned To',
        defaultFlex: 1.5,
      },
      {
        name: 'DateStart',
        header: 'Start Date',
        defaultVisible: false,
        defaultFlex: 1,
        headerAlign: 'start' as any,
        textAlign: 'end' as any,
        dateFormat: 'DD-MMM-YYYY',
        render: ({ value, cellProps: { dateFormat } }) => {
          if (!isNil(value)) {
            return moment(value).format(dateFormat);
          }
        },
        filterEditor: ({ onChange, filterValue }) => {
          return (
            <>
              <GridDateFilterDialog
                fieldName='DateStart'
                filterValue={filterValue}
                onChange={onChange}
              />
            </>
          )

        },
      },
      {
        name: 'Equipment',
        header: 'Equipment',
        defaultFlex: 1.5,
        visible: !isMobile,
      },
      {
        name: 'Department',
        header: 'Department',
        defaultFlex: 1,
        visible: !(isTablet || isMobile),
      },
      {
        name: 'fldWorkList',
        header: 'Worklist Name',
        defaultFlex: 1,
        visible: !(isTablet || isMobile),
      },
      {
        name: 'fldStatus',
        defaultVisible: false,
        header: 'Status',
        defaultFlex: 2,
      },
    ];

    if (completeTaskShow) {
      columns.splice(1, 0,
        {
          id: 'icon',
          header:'Complete task',
          defaultWidth: 65,
          render: ({ data }: any) => (
            <div>
              <CompleteTaskButton initialValue={data.original} componentName='WorkedPerformed' onCancel={onCancel}/>
            </div>
          ),  
        },
      )
    }

    const isActionable =
      Object.values(rowSelected || {}).filter((item: any) => item).length > 0;

    const handleSelectionChange = useCallback(({ selected }) => {
      setRowSelected(selected);
    }, []);

    return (
      <div data-testid="data-grid" className="flex flex-col flex-grow">
        <div className="flex flex-row items-center justify-end">
          {!isEmpty(gridRef?.current.computedGroupBy) &&
            (groupCollapsedStatus ? (
              <div>
                <Tooltip title="Collapse All">
                  <IconButton onClick={CollapseButton}>
                    <UnfoldLessIcon />
                  </IconButton>
                </Tooltip>
              </div>
            ) : (
              <div>
                <Tooltip title="Expand All">
                  <IconButton onClick={CollapseButton}>
                    <UnfoldMoreIcon />
                  </IconButton>
                </Tooltip>
              </div>
            ))}
          {settingsPersonal?.fldWL === 2 && (
            <DeleteRecordBtn
              visible={isActionable}
              records={rowSelected}
              onSelect={setRowSelected}
              warningMessage={taskDeleteWarningMessage}
            />
          )}
        </div>
        {/* Note: due to defaultvisible not working if we use condition to set column,so passing desktopColumn as default.*/}
        <LicensedReactDataGrid
          onRowClick={onRowClick}
          onLoadingChange={onLoadingChange}
          defaultLimit={5000}
          livePagination
          onReady={onReady}
          rowHeight={40}
          defaultGroupBy={[]}
          onGroupByChange={() => gridRef.current.collapseAllGroups()}
          collapsedGroups={collapsedGroups}
          onGroupCollapseChange={setCollapsedGroups}
          loading={loading}
          enableSelection
          defaultFilterValue={filter}
          idProperty="JobNumber"
          columns={columns}
          dataSource={dataSource}
          renderGroupTitle={renderGroupTitle}
          footerRows={footerRows}
          selected={rowSelected}
          onSelectionChange={handleSelectionChange}
          checkboxColumn
          sourceRoot={sourceRoot} 
        />
        
        <Dialog maxWidth="xs" open={Boolean(exportDialog?.visible || false)}>
          <DialogTitle>
            Export data to
            {exportDialog?.title}
          </DialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom>
              You have filters applied. Would you like to export with current
              filters?
            </Typography>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'flex-end' }}>
            <Button
              autoFocus
              onClick={() => exportData(exportDialog?.type, false)}
            >
              No
            </Button>
            <Button
              variant="contained"
              onClick={() => exportData(exportDialog?.type)}
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
);

export default WorkIssuesGrid;
